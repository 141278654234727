<template>
  <!--垫资合同列表-->
  <div style="padding: 20px;">
    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <span style="font-size: 24px;font-weight: 500;color: #707070;">投资记录列表</span>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="88px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="机构名称:">
                    <el-input
                        clearable
                        v-model="where.facilitator_name"
                        placeholder="请输入机构名称"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="合作状态:">
                    <el-select
                        clearable
                        v-model="where.authentication_status"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option label="海信集团有限公司" value="1"/>
                      <el-option label="海信集团有限公司" value="2"/>
                      <el-option label="=海信集团有限公司" value="3"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="服务商名称:">
                    <el-input
                        clearable
                        v-model="where.facilitator_name"
                        placeholder="请输入服务商名称"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="custom" style="margin-left: 20px">
                <el-button class="button" type="primary" @click="openEdit(null)">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">添加 </span>
                </el-button>
              </div>
            </div>
            <!--<div>-->
            <!--  <el-button>导出数据</el-button>-->
            <!--  <el-button @click="ListSetup()">列表设置</el-button>-->
            <!--</div>-->
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >

              <!--自定义列显示 示例-->
              <template v-slot:status= "{ scope }">
                <div v-if="scope.row.status == 0">
                <span style="
                    width: 8px;
                    height: 8px;
                    background: #FF2525;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                 "></span>
                  <span>已终止</span>
                </div>
                <div v-else>
                <span style="
                    width: 8px;
                    height: 8px;
                    background: #25EB61;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                 "></span>
                  <span>合作中</span>
                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="openEdit(scope.row)">详情</el-link>
                <el-link :underline="false" type="warning" style="margin-right: 10px;" @click="openEdit(scope.row)">编辑</el-link>
                <el-popconfirm title="确定执行此操作吗？" @confirm="edit(scope.row)">
                  <el-link slot="reference" :underline="false" type="info" style="margin-right: 10px;">下架</el-link>
                </el-popconfirm>
                <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                  <el-link slot="reference" :underline="false" type="info">删除</el-link>
                </el-popconfirm>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>

    </div>

    <!--编辑弹窗-->
    <inves-edit :data="current" :visible.sync="showEdit"/>

  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getfacilitatorApi, set_statusApi,
} from '@/api/custom'

//引入编辑司机弹窗组件
import InvesEdit from './components/inves-edit.vue'

export default {
  // 组件生效
  components: {
    PackageSortElement,
    InvesEdit
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        facilitator_name:'',
        authentication_status:'全部',
        status:'全部',
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "系统编号",
          prop: "id",
          isShow: true
        },
        {
          label: "投资机构号",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "投资机构名称",
          prop: "facilitator_addres",
          isShow: true
        },
        {
          label: "投资金额",
          prop: "facilitator_addres",
          isShow: true
        },
        {
          label: "投资编号",
          prop: "facilitator_addres",
          isShow: true
        },
        {
          label: "投资开始日期",
          prop: "id",
          isShow: true
        },
        {
          label: "投资结束日期",
          prop: "id",
          isShow: true
        },
        {
          label: "投资状态",
          prop: "status",
          slot: 'status',
          isShow: true
        },
        {
          label: "投资协议",
          prop: "facilitator_addres",
          isShow: true
        },
        {
          label: "操作",
          width: "250",
          slot: "operationSlot",
          isShow: true,
          fixed: 'right'
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //编辑
      current:null,
      showEdit:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      if (this.where.authentication_status === '全部'){
        this.where.authentication_status = ' ';
      }
      if (this.where.status === '全部'){
        this.where.status = ' ';
      }
      getfacilitatorApi(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
        this.where.authentication_status = '全部';
        this.where.status = '全部';
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        facilitator_name:'',
        authentication_status:' ',
        status:' ',
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },


    //编辑
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    //点击返回按钮
    returnPage(){
      this.$router.go(-1);
    },



  }

}
</script>

<style lang="scss" scoped>

</style>
